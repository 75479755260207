var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật đầu ghi",
        visible: _vm.dialogVisible,
        width: "70%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dvrCameraData",
          attrs: {
            "label-width": "140px",
            model: _vm.dvrCameraData,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên đầu ghi", prop: "dvrName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập tên đầu ghi" },
                model: {
                  value: _vm.dvrCameraData.dvrName,
                  callback: function ($$v) {
                    _vm.$set(_vm.dvrCameraData, "dvrName", $$v)
                  },
                  expression: "dvrCameraData.dvrName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Loại đầu ghi", prop: "dvrType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dvrCameraData.dvrType,
                    callback: function ($$v) {
                      _vm.$set(_vm.dvrCameraData, "dvrType", $$v)
                    },
                    expression: "dvrCameraData.dvrType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Dahua" } }, [
                    _vm._v("Dahua"),
                  ]),
                  _c("el-radio", { attrs: { label: "Hix" } }, [_vm._v("Hix")]),
                  _c("el-radio", { attrs: { label: "Xmeye" } }, [
                    _vm._v("Xmeye"),
                  ]),
                  _c("el-radio", { attrs: { label: "Khác" } }, [
                    _vm._v("Khác"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block", width: "45%" },
              attrs: { label: "Tài khoản đầu ghi", prop: "adminDvrAcc" },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập tài khoản đầu ghi" },
                model: {
                  value: _vm.dvrCameraData.adminDvrAcc,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dvrCameraData,
                      "adminDvrAcc",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dvrCameraData.adminDvrAcc",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: {
                display: "inline-block",
                width: "45%",
                float: "right",
              },
              attrs: { label: "Mật khẩu", prop: "adminDvrPassword" },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập mật khẩu" },
                model: {
                  value: _vm.dvrCameraData.adminDvrPassword,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dvrCameraData,
                      "adminDvrPassword",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dvrCameraData.adminDvrPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "45%", display: "inline-block" },
              attrs: { label: "Tên miền", prop: "schoolDomain" },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập tên miền" },
                model: {
                  value: _vm.dvrCameraData.schoolDomain,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dvrCameraData,
                      "schoolDomain",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dvrCameraData.schoolDomain",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: {
                width: "45%",
                display: "inline-block",
                float: "right",
              },
              attrs: { label: "Port", prop: "camPort" },
            },
            [
              _c("el-input", {
                attrs: { type: "number", placeholder: "Nhập port" },
                model: {
                  value: _vm.dvrCameraData.camPort,
                  callback: function ($$v) {
                    _vm.$set(_vm.dvrCameraData, "camPort", $$v)
                  },
                  expression: "dvrCameraData.camPort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "45%", display: "inline-block" },
              attrs: { label: "Device SN", prop: "deviceSN" },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập số Serial Number thiết bị" },
                model: {
                  value: _vm.dvrCameraData.deviceSN,
                  callback: function ($$v) {
                    _vm.$set(_vm.dvrCameraData, "deviceSN", $$v)
                  },
                  expression: "dvrCameraData.deviceSN",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: {
                width: "45%",
                display: "inline-block",
                float: "right",
              },
              attrs: { label: "PortDVR", prop: "portDVR" },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập Port DVR" },
                model: {
                  value: _vm.dvrCameraData.portDVR,
                  callback: function ($$v) {
                    _vm.$set(_vm.dvrCameraData, "portDVR", $$v)
                  },
                  expression: "dvrCameraData.portDVR",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Link DVR", prop: "linkDvr" } },
            [
              _vm.dvrCameraData.dvrType == "Khác"
                ? _c("el-input", {
                    attrs: { placeholder: "Nhập link DVR" },
                    model: {
                      value: _vm.dvrCameraData.linkDvr,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dvrCameraData,
                          "linkDvr",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "dvrCameraData.linkDvr",
                    },
                  })
                : _c("el-input", {
                    attrs: { disabled: "", placeholder: "Nhập link DVR" },
                    model: {
                      value: _vm.changeData,
                      callback: function ($$v) {
                        _vm.changeData =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "changeData",
                    },
                  }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "IP local", prop: "ipLocal" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập IP local" },
                model: {
                  value: _vm.dvrCameraData.ipLocal,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dvrCameraData,
                      "ipLocal",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dvrCameraData.ipLocal",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block", width: "45%" },
              attrs: { label: "Tài khoản modem", prop: "modemAcc" },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập tài khoản modem" },
                model: {
                  value: _vm.dvrCameraData.modemAcc,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dvrCameraData,
                      "modemAcc",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dvrCameraData.modemAcc",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: {
                display: "inline-block",
                width: "45%",
                float: "right",
              },
              attrs: { label: "Mật khẩu", prop: "modemPass" },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập mật khẩu modem" },
                model: {
                  value: _vm.dvrCameraData.modemPass,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dvrCameraData,
                      "modemPass",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dvrCameraData.modemPass",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Kích hoạt", prop: "dvrActive" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dvrCameraData.dvrActive,
                    callback: function ($$v) {
                      _vm.$set(_vm.dvrCameraData, "dvrActive", $$v)
                    },
                    expression: "dvrCameraData.dvrActive",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("Có")]),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("Không"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Ghi chú", prop: "note" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  placeholder: "Nhập ghi chú",
                },
                model: {
                  value: _vm.dvrCameraData.note,
                  callback: function ($$v) {
                    _vm.$set(_vm.dvrCameraData, "note", $$v)
                  },
                  expression: "dvrCameraData.note",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("dvrCameraData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("dvrCameraData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }