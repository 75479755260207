var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cài đặt chế độ xem cho OneCam",
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataInput",
          attrs: {
            "label-width": "160px",
            model: _vm.dataInput,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Điểm danh đến" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.dataInput.arriveViewStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataInput, "arriveViewStatus", $$v)
                    },
                    expression: "dataInput.arriveViewStatus",
                  },
                },
                [_vm._v("Học sinh điểm danh đến mới được xem camera")]
              ),
              _c("el-input", {
                attrs: { placeholder: "Nhập nội dung" },
                model: {
                  value: _vm.dataInput.arriveViewText,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "arriveViewText", $$v)
                  },
                  expression: "dataInput.arriveViewText",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Điểm danh về" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.dataInput.leaveNoViewStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataInput, "leaveNoViewStatus", $$v)
                    },
                    expression: "dataInput.leaveNoViewStatus",
                  },
                },
                [_vm._v("Học sinh điểm danh về không được xem camera")]
              ),
              _c("el-input", {
                attrs: { placeholder: "Nhập nội dung" },
                model: {
                  value: _vm.dataInput.leaveNoViewText,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "leaveNoViewText", $$v)
                  },
                  expression: "dataInput.leaveNoViewText",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Giới hạn xem liên tục" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dataInput.viewLimitStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataInput, "viewLimitStatus", $$v)
                    },
                    expression: "dataInput.viewLimitStatus",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("Có")]),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("Không"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                { staticStyle: { float: "right" } },
                [
                  _vm._v("Số phút xem liên tục     \n        "),
                  _c("el-input-number", {
                    staticStyle: { width: "250px" },
                    model: {
                      value: _vm.dataInput.viewLimitNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "viewLimitNumber", $$v)
                      },
                      expression: "dataInput.viewLimitNumber",
                    },
                  }),
                ],
                1
              ),
              _c("el-input", {
                staticStyle: { "margin-top": "10px" },
                attrs: { placeholder: "Nhập nội dung" },
                model: {
                  value: _vm.dataInput.viewLimitText,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "viewLimitText", $$v)
                  },
                  expression: "dataInput.viewLimitText",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Khoảng thời gian xem" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.dataInput.timeViewStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataInput, "timeViewStatus", $$v)
                    },
                    expression: "dataInput.timeViewStatus",
                  },
                },
                [_vm._v("Giới hạn khoảng thời gian xem camera không")]
              ),
              _c("el-input", {
                attrs: { placeholder: "Nhập nội dung" },
                model: {
                  value: _vm.dataInput.timeViewText,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "timeViewText", $$v)
                  },
                  expression: "dataInput.timeViewText",
                },
              }),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("Giá trị 1"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Bắt đầu",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.startTime1,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "startTime1", $$v)
                            },
                            expression: "dataInput.startTime1",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Kết thúc",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.endTime1,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "endTime1", $$v)
                            },
                            expression: "dataInput.endTime1",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("Giá trị 2"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Bắt đầu",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.startTime2,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "startTime2", $$v)
                            },
                            expression: "dataInput.startTime2",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Kết thúc",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.endTime2,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "endTime2", $$v)
                            },
                            expression: "dataInput.endTime2",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("Giá trị 3"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Bắt đầu",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.startTime3,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "startTime3", $$v)
                            },
                            expression: "dataInput.startTime3",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Kết thúc",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.endTime3,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "endTime3", $$v)
                            },
                            expression: "dataInput.endTime3",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("Giá trị 4"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Bắt đầu",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.startTime4,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "startTime4", $$v)
                            },
                            expression: "dataInput.startTime4",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Kết thúc",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.endTime4,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "endTime4", $$v)
                            },
                            expression: "dataInput.endTime4",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("Giá trị 5"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Bắt đầu",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.startTime5,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "startTime5", $$v)
                            },
                            expression: "dataInput.startTime5",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Kết thúc",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.endTime5,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "endTime5", $$v)
                            },
                            expression: "dataInput.endTime5",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }