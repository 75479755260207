var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "search-input",
          staticStyle: {
            "margin-bottom": "-20px",
            position: "relative",
            "z-index": "1",
            "margin-top": "5px",
          },
          style: { visibility: _vm.showDataSearch ? "visible" : "hidden" },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "input-common",
              staticStyle: { width: "140px" },
              attrs: { clearable: "", placeholder: "Chọn khối" },
              on: {
                change: function ($event) {
                  return _vm.changeGradeMethod()
                },
              },
              model: {
                value: _vm.dataSearch.idGrade,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "idGrade", $$v)
                },
                expression: "dataSearch.idGrade",
              },
            },
            _vm._l(_vm.gradeList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { value: item.id, label: item.gradeName },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "input-common",
              staticStyle: { width: "140px" },
              attrs: { filterable: "", clearable: "", placeholder: "Chọn lớp" },
              on: {
                change: function ($event) {
                  return _vm.handleClickTab()
                },
              },
              model: {
                value: _vm.dataSearch.idClass,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "idClass", $$v)
                },
                expression: "dataSearch.idClass",
              },
            },
            _vm._l(_vm.classList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { value: item.id, label: item.className },
              })
            }),
            1
          ),
          _c(
            "el-input",
            {
              staticStyle: { width: "280px" },
              attrs: { placeholder: "Nhập tên lớp", clearable: "" },
              on: {
                clear: function ($event) {
                  return _vm.handleClickTab()
                },
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleClickTab()
                },
              },
              model: {
                value: _vm.dataSearch.className,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "className", $$v)
                },
                expression: "dataSearch.className",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.handleClickTab()
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClickTab },
              model: {
                value: _vm.activeTabName,
                callback: function ($$v) {
                  _vm.activeTabName = $$v
                },
                expression: "activeTabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  staticStyle: { width: "60%" },
                  attrs: { label: "Nguồn tin", name: "oneCamNewTab" },
                },
                [
                  _vm._l(_vm.oneCameDataList, function (oneCameData, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        index == 0
                          ? _c("div", [_vm._v("Cài đặt tin tức OneCam")])
                          : index == 1
                          ? _c("div", [_vm._v("Cài đặt tin tức app plus")])
                          : index == 2
                          ? _c("div", [_vm._v("Cài đặt tin tức app teacher")])
                          : index == 3
                          ? _c("div", [_vm._v("Cài đặt tin tức app onekids")])
                          : _vm._e(),
                        _c("br"),
                        _c(
                          "el-form",
                          {
                            ref: "oneCameData",
                            refInFor: true,
                            attrs: {
                              "label-width": "150px",
                              model: oneCameData,
                              "label-position": "top",
                              rules: _vm.rules,
                            },
                          },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Hệ thống",
                                            prop: "oneCamNumber",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { placeholder: "OneCam" },
                                            model: {
                                              value: oneCameData.oneCamNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  oneCameData,
                                                  "oneCamNumber",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "oneCameData.oneCamNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Nhà trường",
                                            prop: "schoolNumber",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Nhà trường",
                                            },
                                            model: {
                                              value: oneCameData.schoolNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  oneCameData,
                                                  "schoolNumber",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "oneCameData.schoolNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content bg-purple" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                          attrs: {
                                            label: "Tổng số hiển thị",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                oneCameData.schoolNumber +
                                                  oneCameData.oneCamNumber
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Nguồn link mở rộng" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: oneCameData.extendLinkStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          oneCameData,
                                          "extendLinkStatus",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "oneCameData.extendLinkStatus",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: true } }, [
                                      _vm._v("Link mở rộng"),
                                    ]),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: false } },
                                      [_vm._v("Tin nhà trường")]
                                    ),
                                    oneCameData.extendLinkStatus
                                      ? _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "Nhập link mở rộng(https://...)",
                                          },
                                          model: {
                                            value: oneCameData.extendLink,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                oneCameData,
                                                "extendLink",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "oneCameData.extendLink",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("hr"),
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: {
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButtonOneCam,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateOneCamSettingMethod()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
                    ]
                  ),
                  _c("br"),
                  _c("br"),
                  _c("br"),
                  _c("br"),
                ],
                2
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "View Setting", name: "oneCamSettingTab" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingData,
                          expression: "loadingData",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "empty-text": _vm.textTableSetting,
                        "element-loading-text": _vm.$tableLoading,
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.onecamSettingList,
                        "row-style": _vm.tableRowStyle,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": _vm.$tableMaxHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "className",
                          label: "Tên lớp",
                          width: "170",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Điểm danh đến",
                          width: "120",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: scope.row.arriveViewStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "arriveViewStatus",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.arriveViewStatus",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Điểm danh về",
                          width: "120",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: scope.row.leaveNoViewStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "leaveNoViewStatus",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.leaveNoViewStatus",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Giới hạn số phút",
                          width: "130",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: scope.row.viewLimitStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "viewLimitStatus",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.viewLimitStatus",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Số phút",
                          prop: "viewLimitNumber",
                          width: "90",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Giới hạn khoảng",
                          width: "130",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: scope.row.timeViewStatus,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "timeViewStatus", $$v)
                                    },
                                    expression: "scope.row.timeViewStatus",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Khoảng thời gian" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.startTime1 != null ||
                                scope.row.endTime1 != null
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticStyle: { "margin-right": "8px" },
                                        attrs: { type: "", effect: "plain" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.startTime1) +
                                            " - " +
                                            _vm._s(scope.row.endTime1)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.startTime2 != null ||
                                scope.row.endTime2 != null
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticStyle: { "margin-right": "8px" },
                                        attrs: { type: "", effect: "plain" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.startTime2) +
                                            " - " +
                                            _vm._s(scope.row.endTime2)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.startTime3 != null ||
                                scope.row.endTime3 != null
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticStyle: { "margin-right": "8px" },
                                        attrs: { type: "", effect: "plain" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.startTime3) +
                                            " - " +
                                            _vm._s(scope.row.endTime3)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.startTime4 != null ||
                                scope.row.endTime4 != null
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticStyle: { "margin-right": "8px" },
                                        attrs: { type: "", effect: "plain" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.startTime4) +
                                            " - " +
                                            _vm._s(scope.row.endTime4)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.startTime5 != null ||
                                scope.row.endTime5 != null
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticStyle: { "margin-right": "8px" },
                                        attrs: { type: "", effect: "plain" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.startTime5) +
                                            " - " +
                                            _vm._s(scope.row.endTime5)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tác vụ",
                          width: "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.settingViewSettingMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Cài đặt")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: { label: "Camera setting", name: "cameraSettingTab" },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingData,
                          expression: "loadingData",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "empty-text": _vm.textTableSetting,
                        "element-loading-text": _vm.$tableLoading,
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.cameraSettingList,
                        "row-style": _vm.tableRowStyle,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": _vm.$tableMaxHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "className",
                          label: "Tên lớp",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Danh sách camera" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return _vm._l(
                                scope.row.cameraList,
                                function (item) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: item.id,
                                      staticStyle: { "margin-right": "8px" },
                                      attrs: { type: "", effect: "plain" },
                                    },
                                    [_vm._v(_vm._s(item.camName))]
                                  )
                                }
                              )
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tác vụ",
                          width: "130",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addCameraForClassMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Thêm Camera")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Camera list", name: "cameraListTab" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingData,
                          expression: "loadingData",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "empty-text": _vm.textTableList,
                        "element-loading-text": _vm.$tableLoading,
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.cameraList,
                        "row-style": _vm.tableRowStyle,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": _vm.$tableMaxHeight,
                        border: "",
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChangeCameraList,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          type: "index",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          prop: "camName",
                          label: "Camera Name",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dvrCamera.dvrName",
                          label: "DVR Name",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "camChanel",
                          label: "Chanel",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "camStream",
                          label: "Stream",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "camChanelOneCam",
                          label: "OneCam Chanel",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "camStreamOneCam",
                          label: "OneCam Stream",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "linkCam",
                          label: "Camera Link",
                          width: "550",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row.dvrCamera.linkDvr) +
                                      _vm._s(scope.row.linkCam)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Active",
                          width: "70",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.activeCameraMethod(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.camActive,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "camActive", $$v)
                                    },
                                    expression: "scope.row.camActive",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "note", label: "Ghi chú", width: "250" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          label: "Action",
                          width: "140",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateCameraMethod(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("button.update")))]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCameraOneMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("button.delete")))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { float: "right", margin: "15px 0" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button-bottom",
                          attrs: { type: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.addCameraMethod()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-circle-check" }),
                          _vm._v("\n            Thêm camera\n          "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "DVR config", name: "dvrConfigTab" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingData,
                          expression: "loadingData",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "empty-text": _vm.textTableDVR,
                        "element-loading-text": _vm.$tableLoading,
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.dvrCameraList,
                        "row-style": _vm.tableRowStyle,
                        "header-cell-style": _vm.tableHeaderColor,
                        "max-height": _vm.$tableMaxHeight,
                        border: "",
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChangeDvrcamera,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          type: "index",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          prop: "dvrName",
                          label: "DVR Name",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dvrType",
                          label: "Factory",
                          width: "100",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "adminDvrAcc",
                          label: "Acc",
                          align: "center",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "adminDvrPassword",
                          label: "Pass",
                          align: "center",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "schoolDomain",
                          label: "School Domain",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "camPort",
                          label: "Port",
                          align: "center",
                          width: "65",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "linkDvr",
                          label: "Link DVR",
                          width: "490",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "ipLocal",
                          label: "IP Local",
                          align: "center",
                          width: "80",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "modemAcc",
                          label: "Modem Acc",
                          align: "center",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "modemPass",
                          label: "Modem Pass",
                          align: "center",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "DVR Active",
                          align: "center",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.activeDvrcameraMethod(
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.dvrActive,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "dvrActive", $$v)
                                    },
                                    expression: "scope.row.dvrActive",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "note", label: "Note", width: "240" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          label: "Tác vụ",
                          width: "140",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateDvrcameraMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("button.update")))]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteDvrcameraOneMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("button.delete")))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { float: "right", margin: "15px 0" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button-bottom",
                          attrs: { type: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.addDvrCameraMethod()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-circle-check" }),
                          _vm._v("\n            Thêm đầu ghi\n          "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CameraUpdateDialog", {
        ref: "CameraUpdateDialog",
        attrs: { dialogVisible: _vm.showUpdateCameraDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseUpdateCameraMethod()
          },
        },
      }),
      _c("CameraCreateDialog", {
        ref: "CameraCreateDialog",
        attrs: { dialogVisible: _vm.showCreateCameraDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateCameraMethod()
          },
        },
      }),
      _c("AddCameraForClassDialog", {
        ref: "AddCameraForClassDialog",
        attrs: { dialogVisible: _vm.showAddCameraDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseAddCameraMethod()
          },
        },
      }),
      _c("DvrCameraCreateDialog", {
        attrs: { dialogVisible: _vm.showCreateDvrCameraDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateDvrCameraMethod()
          },
        },
      }),
      _c("DvrCameraUpdateDialog", {
        ref: "DvrCameraUpdateDialog",
        attrs: { dialogVisible: _vm.showUpdateDvrCameraDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseUpdateDvrCameraMethod()
          },
        },
      }),
      _c("OneCameSettingDialog", {
        ref: "OneCameSettingDialog",
        attrs: { dialogVisible: _vm.showOneCamSettingDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseOneCamSettingMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }